import { useEffect, useState } from 'react';
import { useRoles } from './useRoles';
import { AppAction, isAllowed } from './Roles';

export function useIsAllowed(action: AppAction) {
    const roles = useRoles();
    const [allowed, setAllowed] = useState<boolean>(false);

    useEffect(() => {
        setAllowed(isAllowed(action, roles));
    }, [action, roles]);

    return allowed;
}
