import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { ReactNode, useEffect, useState } from 'react';

interface ConditionalLinkProps {
    href: string;
    children: ReactNode;
}

export function useCurrentURL() {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const [currentURL, setCurrentURL] = useState<string>();

    useEffect(() => {
        let newURL = `${pathname}`;
        if (!newURL.endsWith('/')) {
            newURL += '/';
        }
        const params = searchParams?.toString();
        if (params) {
            newURL += `?${params}`;
        }
        setCurrentURL(newURL);
    }, [pathname, searchParams]);

    return currentURL;
}

export default function ConditionalLink({ href, children }: ConditionalLinkProps): JSX.Element {
    const currentURL = useCurrentURL();

    const [showLink, setShowLink] = useState(false);

    useEffect(() => {
        // Check if the href would navigate to a different route
        setShowLink(href !== currentURL);
    }, [currentURL, href]);

    if (!showLink) {
        return <>{children}</>;
    }

    return <Link href={href}>{children}</Link>;
}
