import { useEffect } from 'react';
import { useJsonEqualState } from '../../hooks/useJsonEqualState';
import { useAuthenticationResult } from './useAuthenticationResult';
import { getRoles } from './getRoles';

export function useRoles(): string[] {
    const [result] = useAuthenticationResult();
    const [roles, setRoles] = useJsonEqualState<string[]>();

    useEffect(() => {
        const newRoles = getRoles(result);
        setRoles(newRoles);
    }, [result, roles, setRoles]);

    return roles;
}
