import { AuthenticationResult } from '@azure/msal-browser';

export interface ClaimWithRoles {
    roles?: string[];
}

export function getRoles(result: AuthenticationResult): string[] {
    if (!result) {
        return [];
    }

    const claims = result?.idTokenClaims as ClaimWithRoles;
    const roles = claims?.roles || [];
    return roles;
}
