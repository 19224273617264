import memoize from 'fast-memoize';

export function _formatDigits(digits: number): Intl.NumberFormat {
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
        useGrouping: true,
    });
}

// Use memoize to avoid overhead from creating Intl number formatter
export const formatDigits = memoize(_formatDigits);
