export function roundedString(value: number, fractionDigits = 0): string {
    const roundedValue = value.toFixed(fractionDigits);

    let formatted = roundedValue.toString();

    // Remove trailing 0's after the period
    while (formatted.endsWith('0') && formatted.includes('.')) {
        formatted = formatted.substring(0, formatted.length - 1);
    }

    // Remove trailing period
    if (formatted.endsWith('.')) {
        formatted = formatted.substring(0, formatted.length - 1);
    }

    return formatted;
}
