import React, { useState } from 'react';
import { getAppConfigURL } from '@/hooks/api/getAppConfigURL';
import AuthenticatedJsonContent from '@/app/(authenticated)/content/jsonGet/template';
import { AppConfig } from '@/common/interfaces/containers/config/AppConfig';

export function BriefModeDetails(): JSX.Element {
    const appConfigURL = getAppConfigURL();
    const [appConfig, setAppConfig] = useState<AppConfig>();

    // Display the details of the mode in settings or the current one
    const settings = appConfig?.settings;
    const mode = settings?.mode;
    const modeDetails = appConfig?.modesLookup[mode];

    return (
        <AuthenticatedJsonContent params={{ url: appConfigURL, setData: setAppConfig }}>
            {modeDetails?.description || <>&nbsp;</>}
        </AuthenticatedJsonContent>
    );
}
