'use client';

import { AppAction } from '@/contexts/TokenContext/Roles';
import { useIsAllowed } from '@/contexts/TokenContext/useIsAllowed';
import React from 'react';

export interface RestrictedContentParams {
    params?: {
        action?: AppAction;
        anyAction?: AppAction[];
    };
    children?: React.ReactNode;
}

export default function RestrictedContent(params: RestrictedContentParams): JSX.Element {
    const action = params?.params?.action;

    const allowed = useIsAllowed(action);
    if (!allowed) {
        return null;
    }

    return <React.Fragment>{params?.children}</React.Fragment>;
}
