'use client';
import React, { useRef, useState } from 'react';
import { CountSummary, CountType } from '@/common/interfaces/containers/config/Count';
import AuthenticatedJsonContent from '@/app/(authenticated)/content/jsonGet/template';
import { getCountSummaryURL } from './getCountSummaryURL';
import { SimpleBar } from '@/components/SimpleBar/SimpleBar';
import { percentage } from '@/components/SimpleBar/percentage';
import { PopoverBody } from 'react-bootstrap';
import { ContentWithPopup } from '@/app/(authenticated)/orders/(orderId)/problemIndicators/RowWithSupplementalContent';
import { roundedString } from '@/common/util/numbers/roundedString';
import styles from './OutageCountBar.module.scss';

function roundPercentageWithoutPower(value: number, count: number): string {
    let rounded = roundedString(value, 2);

    // If 100% but we still have outages, show as 99.99%
    if (count > 0) {
        if (rounded === '100') {
            rounded = '99.99';
        }

        if (rounded === '0') {
            rounded = '0.01';
        }
    }

    return rounded;
}

export function OutageCountBar(): JSX.Element {
    const [countSummary, setCountSummary] = useState<CountSummary>();
    const counts = countSummary?.counts;
    const containerRef = useRef(null);

    const url = getCountSummaryURL();

    const outageCount = counts?.[CountType.DisplayOutages];

    let message = null;

    let popup = null;
    if (outageCount !== undefined) {
        message = <>No customers out</>;

        if (outageCount > 0) {
            const premiseCount = counts?.[CountType.Premises];
            const percentOut = percentage(outageCount, premiseCount);
            const formatPercent = roundPercentageWithoutPower(percentOut, outageCount);
            message = (
                <>
                    <b>{formatPercent}%</b> of customers out
                </>
            );
        }

        popup = (
            <>
                <PopoverBody>{message}</PopoverBody>
            </>
        );
    }

    return (
        <>
            <section className={styles.main} ref={containerRef}>
                <ContentWithPopup
                    params={{
                        popup: popup,
                        delay: { show: 300, hide: 100 },
                        containerRef: containerRef,
                    }}
                >
                    <SimpleBar
                        className="outages"
                        value={counts?.[CountType.DisplayOutages]}
                        max={counts?.[CountType.Premises]}
                    />
                </ContentWithPopup>
            </section>
            <AuthenticatedJsonContent params={{ url: url, setData: setCountSummary }} />
        </>
    );
}
