import { CosmosDocument } from '../CosmosDocument';

export enum CountType {
    // Internal counts
    OpenOrders = 'openOrders',
    OpenOutages = 'openOutages',
    OpenOutagesWithValidEstimate = 'openOutagesWithValidEstimate',
    OpenOutagesWithExpiredEstimate = 'openOutagesWithExpiredEstimate',

    // Count of premises (currently from ESB)
    Premises = 'premises',

    // Counts from Inservice
    InserviceCrews = 'inserviceCrews',
    InserviceOutages = 'inserviceOutages',

    // Counts from ADMS
    OMSCrews = 'omsCrews',
    OMSOutages = 'omsOutages',

    // Overrides
    OverrideCrews = 'overrideCrews',
    OverrideOutages = 'overrideOutages',

    // Value used for display to channels
    DisplayCrews = 'displayCrews',
    DisplayOutages = 'displayOutages',

    // Used for a summary of all counts
    Summary = 'summary',

    // Used for counts changes
    Outages = 'outages',
    Crews = 'crews',
    OutagesCrews = 'outagesCrews',
}

export interface CountSummary extends CosmosDocument {
    type: CountType;
    time: string;
    counts: Record<string, number>;

    // Keep track of which value we're using to display
    outagesDisplayOverride?: CountType;
    outagesDisplayOverrideExpiration?: string;
    crewsDisplayOverride?: CountType;
    crewsDisplayOverrideExpiration?: string;
    overrideOutageEquation?: string;
}
