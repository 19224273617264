'use client';
import React, { useState } from 'react';
import { CountSummary, CountType } from '@/common/interfaces/containers/config/Count';
import { formatCount } from '../formatCount';
import AuthenticatedJsonContent from '@/app/(authenticated)/content/jsonGet/template';
import { getCountSummaryURL } from './getCountSummaryURL';

export function BriefCountDetails(): JSX.Element {
    const [countSummary, setCountSummary] = useState<CountSummary>();
    const counts = countSummary?.counts;

    const url = getCountSummaryURL();

    let content = <>&nbsp;</>;

    const outageCount = counts?.[CountType.DisplayOutages];
    if (outageCount !== undefined) {
        content = <>{formatCount(outageCount)}</>;
    }

    return (
        <AuthenticatedJsonContent params={{ url: url, setData: setCountSummary }}>{content}</AuthenticatedJsonContent>
    );
}
